// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";
import {
  CustomerStatusCssClasses,
  CustomerStatusTitles,
} from "../../UIHelpers";


function getIndex(stat) {
  if (stat === true) {
    return 0;
  } else {
    return 1;
  }
}

export function StatusColumnFormatter(cellContent, row) {
  // console.log(row.status, cellContent);

  const getLabelCssClasses = () => {
    return `label label-lg label-light-${
      CustomerStatusCssClasses[getIndex(row.verified)]
    } label-inline`;
  };
  return (
    <span className={getLabelCssClasses()}>
      {/* {CustomerStatusTitles[cellContent === true ? 0 : 1]} */}
      {CustomerStatusTitles[getIndex(row.verified)]}
      {/* {getIndex(cellContent)} */}
    </span>
  );
}
