import React, {useEffect, useState} from "react";
import { CustomersTable } from "./database";
// import { CustomersFilter } from "./filter";
import {
    StatsWidget11,
    StatsWidget12,
} from "../../../../_metronic/_partials/widgets";

import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
  } from "../../../../_metronic/_partials/controls";

import { shallowEqual, useSelector } from "react-redux";
import {  useDispatch } from "react-redux";
import * as actions from "../_redux/dashboard/Actions";
import * as trans_actions from "../../Transactions/_redux/trans/Actions";

import {Dropdown, Button, ButtonGroup} from "react-bootstrap";

import {Chart} from "./chart";

import {LocationChart} from "./location_chart";


export function Dashboard() {

    const dispatch = useDispatch();

    const [tab, setTab] = useState('Location');
    const [tranferCount, setTrans] = useState(null);

    const tabs = [
        {
            "name": "Location", "key": 'location'
        },
        {
            "name": "Dangers", "key": 'danger'
        }
    ]

    const { totalCount, activeCount, danger_data, location_data, userCount, orgCount } = useSelector(
        (state) => ({
            totalCount: state.dashboard.totalCount,
            activeCount: state.dashboard.activeCount,
            danger_data: state.dashboard.danger_data,
            location_data: state.dashboard.location_data,
            userCount: state.dashboard.userCount,
            orgCount: state.dashboard.orgCount
        }),
        shallowEqual
    );


    useEffect(() => {
        // dispatch(actions.fetchAllData('incident_count', '?count=true' ));
        // dispatch(actions.fetchAllData('active_incident_count', '?count=true&status=active' ));
        dispatch(actions.fetchAllData('user_count', '?count=true&type=customer' ));
        dispatch(trans_actions.fetchAllData('trans_data', '?count=true' )).then((res) => {
            console.log(res);
            if (res && res.length > 0) {
                setTrans(res[0].total_count)
            } else {
                setTrans(0);
            }
        });
        // dispatch(actions.fetchAllData('analytics_danger', '?type=danger_types' ));
        // dispatch(actions.fetchAllData('analytics_location', '?type=location' ));
    
        
    }, [dispatch])


    return (<>
     

        <div className="row">
          

            <div className="col-lg-3">
                <StatsWidget11 
                title  = {'Users'}
                key_id = {7} totalCount = {userCount} className="card-stretch card-stretch-full gutter-b"/>
            </div>
            <div className="col-lg-3">
                <StatsWidget12 
                title  = {'Transactions'}
                type = 'org'
                key_id = {11}
                activeCount = {tranferCount || '...'} className="card-stretch card-stretch-full gutter-b"/>
            </div>
        
        
            <div className="col-lg-3">
                <StatsWidget11
                    title  = {'Withdraws'}
                    key_id = {8} totalCount = {orgCount} className="card-stretch card-stretch-full gutter-b"/>
            </div>
            <div className="col-lg-3">
                <StatsWidget12 
                    type = 'requests'
                    title  = {'Deposits'}
                    key_id = {12}
                    activeCount = {activeCount} className="card-stretch card-stretch-full gutter-b"/>
            </div>
        
         
        </div>
        <div className="row">
            <div className="col-lg-12 col-xxl-12">
                <Card>
                    <CardHeader title="All Users">
                        <CardHeaderToolbar>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        {/* <CustomersFilter /> */}
                        <CustomersTable />
                    </CardBody>
                </Card>
                {/* <CustomersTable/> */}
            </div>
        </div>
</>);
}
