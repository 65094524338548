import * as requestFromServer from "./Crud";
import {TransSlice, callTypes} from "./Slice";

const {actions} = TransSlice;


export const fetchAllData = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDatas(type, queryParams)
    .then(response => {
      var res = response.data;
      // console.log("Server response is", response.data);
      if (type === 'accounts_count') {
        dispatch(actions.allIncidentCount(res?.success[0]?.total_count || 0));
      } else if (type === 'trans_data') {
        // dispatch(actions.allIncidentCount(res?.success[0]?.total_count || 0));
        return res?.success;
      } else if (type === 'data') {
        dispatch(actions.data(res.success));
      } else if (type === 'exchange') {
        dispatch(actions.exchange(res.success));
      } 
      // const { totalCount, entities } = response.data;
      // dispatch(actions.DatasFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Datas";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchData = id => dispatch => {
  if (!id) {
    return dispatch(actions.DataFetched({ DataForEdit: undefined }));
  }

  // dispatch(actions.startCall({ callType: callTypes.action }));
  // return requestFromServer
  //   .getDataById(id)
  //   .then(response => {
  //     const Data = response.data;
  //     dispatch(actions.DataFetched({ DataForEdit: Data }));
  //   })
  //   .catch(error => {
  //     error.clientMessage = "Can't find Data";
  //     dispatch(actions.catchError({ error, callType: callTypes.action }));
  //   });
};

export const deleteData = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteData(id)
    .then(response => {
      dispatch(actions.DataDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete Data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const addData = DataForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addNewData(DataForCreation)
    .then(response => {
      const { Data } = response.data;
      dispatch(actions.DataCreated({ Data }));
    })
    .catch(error => {
      error.clientMessage = "Can't create Data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateData = Data => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateData(Data)
    .then(() => {
      dispatch(actions.DataUpdated({ Data }));
    })
    .catch(error => {
      error.clientMessage = "Can't update Data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateDatasStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForDatas(ids, status)
    .then(() => {
      dispatch(actions.DatasStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update Datas status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteDatas = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteDatas(ids)
    .then(() => {
      dispatch(actions.DatasDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete Datas";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
