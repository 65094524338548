import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  RESET_TOKEN: "[RESET] TOKEN",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  OrgLoaded: "GET ORG"
};

const initialAuthState = {
  user: undefined,
  organization: null,
  authToken: undefined
};

export const reducer = persistReducer(
  { storage, key: "v706-demo3-auth", whitelist: ["user", "authToken", "organization"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.RESET_TOKEN: {
        const accessToken = action.payload?.accessToken;
        return { ...state, authToken: accessToken };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.OrgLoaded: {
        const { organization } = action.payload;
        return { ...state, organization };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: authToken => ({ type: actionTypes.Login, payload: { authToken } }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillToken: (accessToken) => ({ type: actionTypes.RESET_TOKEN, payload: { accessToken} }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  fulfillOrg: user => ({ type: actionTypes.OrgLoaded, payload: { organization: user } })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();
    // console.log(user); return;
    yield put(actions.fulfillUser(user.user));
    // yield put(actions.fulfillOrg(user.organization));
  });
}
