import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import { DashboardPage } from "./modules/Dashbord/pages";

const RequestRoot = lazy(() =>
  import("./modules/Request/pages")
);

const AccountRoots = lazy(() =>
  import("./modules/Admin/pages")
);


const UserRoots = lazy(() =>
  import("./modules/User/pages")
);


const LogsRoots = lazy(() =>
  import("./modules/Logs/pages")
);

const SettingsRoot = lazy(() =>
  import("./modules/Settings/pages")
);

const TransactionsRoot = lazy(() =>
  import("./modules/Transactions/pages")
);

const ExchangeRoot = lazy(() =>
  import("./modules/Exchange/pages")
);

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                <ContentRoute path="/dashboard" component={DashboardPage}/>
                {/* <ContentRoute path="/builder" component={BuilderPage}/>
                <ContentRoute path="/my-page" component={MyPage}/> */}
                {/* <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/> */}
                <Route path="/transactions" component={TransactionsRoot}/>
                <Route path="/exchange" component={ExchangeRoot}/>
                <Route path="/users" component={UserRoots}/>
                <Route path="/admin" component={AccountRoots}/>
                <Route path="/settings" component={SettingsRoot}/>
                <Route path="/logs" component={LogsRoots}/>
                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
