import axios from "axios";
import config from "../../../../../config";

const URL = process.env.REACT_APP_API_URL;

// CREATE =>  POST: add a new data to the server
export function addNewData(data) {
  return axios.post(URL, { data });
}

// READ
export function getAllDatas() {
  return axios.get(URL);
}

export function getSataById(dataId) {
  return axios.get(`${URL}/${dataId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findDatas(type, queryParams) {
  // return axios.post(`${URL}/all?`, { queryParams });
  if (type === 'incident_count') {
    return axios.get(`${URL}/admin/all${queryParams}`);
  } else if (type === 'active_incident_count') {
    return axios.get(`${URL}/admin/all${queryParams}`);
  } else if (type === 'alerts') {
    return axios.get(`${URL}/admin/all${queryParams}`);
  } else if (type === 'analytics_danger') {
    return axios.get(`${URL}/admin/analytics${queryParams}`);
  } else if (type === 'analytics_location') {
    return axios.get(`${URL}/admin/analytics${queryParams}`);
  } else if (type === 'users') {
    return axios.get(`${URL}auth/user/all${queryParams}`);
  }  else if (type === 'user_count') {
    // return axios.get(`${URL}accounts/count`);
    return axios.get(`${URL}auth/user/all${queryParams}`);
  } else if (type === 'org_count') {
    return axios.get(`${config.URL}api/organization/all${queryParams}`);
  } 
}

// UPDATE => PUT: update the data on the server
export function updateData(data) {
  return axios.put(`${URL}/${data.id}`, { data });
}

// UPDATE Status
export function updateStatusForDatas(ids, status) {
  return axios.post(`${URL}/updateStatusFordatas`, {
    ids,
    status
  });
}

// DELETE => delete the data from the server
export function deleteData(dataId) {
  return axios.delete(`${URL}/${dataId}`);
}

// DELETE datas by ids
export function deleteDatas(ids) {
  return axios.post(`${URL}/deletedatas`, { ids });
}
