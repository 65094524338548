/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>

          <li
              className={`menu-item ${getMenuItemActive("/transactions", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/transactions">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
              <span className="menu-text">Transactions</span>
            </NavLink>
          </li>


          <li
              className={`menu-item ${getMenuItemActive("/currency", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/exchange">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
              <span className="menu-text">Currency</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}


          {/* Error Pages */}
          {/*begin::1 Level*/}
          {/* <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/request", true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/request">
            <span className="svg-icon menu-icon">
              <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Thunder-move.svg")}
              />
           
            </span>
              <span className="menu-text">Requests</span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow"/>
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Requests</span>
                </span>
                </li>

                <li
                    className={`menu-item ${getMenuItemActive("/request/all")}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/request/all">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">All</span>
                  </NavLink>
                </li>

                <li
                    className={`menu-item ${getMenuItemActive("/request/pending")}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/request/pending">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Pending</span>
                  </NavLink>
                </li>

                <li
                    className={`menu-item ${getMenuItemActive("/request/active")}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/request/active">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Active</span>
                  </NavLink>
                </li>
            
                <li
                    className={`menu-item ${getMenuItemActive("/request/completed")}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/request/completed">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Completed</span>
                  </NavLink>
                </li>
                
                <li
                    className={`menu-item ${getMenuItemActive("/request/cancelled")}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/request/cancelled">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Cancelled</span>
                  </NavLink>
                </li>
            
              </ul>
            </div>
          </li> */}



          <li
              className={`menu-item ${getMenuItemActive("/users", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/users">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/>
            </span>
              <span className="menu-text">User Management</span>
            </NavLink>
          </li>

          <li
              className={`menu-item ${getMenuItemActive("/admin", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/admin">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/>
            </span>
              <span className="menu-text">Admin Accounts</span>
            </NavLink>
          </li>

          <li
              className={`menu-item ${getMenuItemActive("/logs", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/logs">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Clipboard-list.svg")}/>
            </span>
              <span className="menu-text">Logs</span>
            </NavLink>
          </li>


          <li
              className={`menu-item ${getMenuItemActive("/settings", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/settings">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Settings4.svg")}/>
            </span>
              <span className="menu-text">Settings</span>
            </NavLink>
          </li>
  
        </ul>

        {/* end::Menu Nav */}
      </>
  );
}
