import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { DashboardSlice } from "../app/modules/Dashbord/_redux/dashboard/Slice";
import { RequestSlice } from "../app/modules/Request/_redux/request/Slice";
import {AccountSlice} from "../app/modules/Admin/_redux/accounts/Slice";
import {LogsSlice} from "../app/modules/Logs/_redux/logs/Slice";
import {CosumersSlice} from "../app/modules/User/_redux/customers/Slice";
import {TransSlice} from "../app/modules/Transactions/_redux/trans/Slice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  dashboard: DashboardSlice.reducer,
  requests: RequestSlice.reducer,
  accounts: AccountSlice.reducer,
  logs: LogsSlice.reducer,
  trans: TransSlice.reducer,
  customers: CosumersSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
